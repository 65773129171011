import { ShoppingCart } from 'tabler-icons-react';
import Link from 'next/link';
import {
    Handshake,
    LockSimple,
    Receipt,
    Scroll,
    ShuffleAngular,
    Storefront,
    Table,
    TrendUp,
    UserFocus,
} from 'phosphor-react';
import { Image } from '@mantine/core';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useAppContext } from '../contexts/appContext';
import { AllPagesType } from './navBar';
import { hideFromBennu } from '../utils/auth';

const Container = ({ children, twoRows }: { children: JSX.Element[]; twoRows: boolean }) => (
    <div
        className={classNames('grid grid-cols-3 pt-4 grid-flow-row mt-8 gap-8', {
            '!grid-cols-2': !twoRows,
        })}
    >
        {children}
    </div>
);

const CustomLink = ({
    children,
    href,
    className,
    target = true,
    id,
}: {
    children: JSX.Element[];
    href: string;
    className?: string;
    target?: boolean;
    id?: string;
}) => (
    <Link id={id} href={href} className={className} target={target ? '_blank' : undefined}>
        {children}
    </Link>
);

const SubContainer = ({
    children,
    href,
    target = true,
    disabled,
    className,
    id,
}: {
    children: JSX.Element[];
    href?: AllPagesType;
    target?: boolean;
    disabled?: boolean;
    className?: string;
    id?: string;
}) => {
    const styling = 'flex w-[272px] items-start flex-col rounded-lg gap-1 px-6 pb-6 bg-[#1a1f26]';
    if (disabled) return <div className={`${styling} opacity-50`}>{children}</div>;
    return (
        <CustomLink
            id={id}
            target={target}
            href={href !== '/' ? `/${href}` : href}
            className={`${styling} border-secondary-bg transition-colors border-1 ${className}`}
        >
            {children}
        </CustomLink>
    );
};
const CardIconContainer = ({
    children,
    displayAccessMessage,
}: {
    children: JSX.Element;
    displayAccessMessage?: boolean;
}) => {
    const iconStyling =
        'rounded-full bg-secondary p-6 -mt-4 hover:border-sky-600 transition-colors';
    if (displayAccessMessage)
        return (
            <div className="flex justify-between w-full">
                <div className={iconStyling}>{children}</div>
                <div className="flex mt-2.5 -mr-4 items-end flex-col">
                    <LockSimple size={24} className="my-1" />
                    <span className="text-[10px]">INQUIRE FOR ACCESS</span>
                </div>
            </div>
        );
    return <div className={iconStyling}>{children}</div>;
};
const CardTitleContainer = ({ children }: { children: string }) => (
    <h2 className="text-left text-base mt-5">{children}</h2>
);
const CardDescriptionContainer = ({ children }: { children: string }) => (
    <span className="w-[224px] text-sm text-gray-400">{children}</span>
);
const HomePage = () => {
    const { user } = useAppContext();
    const router = useRouter();

    const showHelm = useMemo(() => {
        const forceShowHelm = !!router.query.helm;
        return (
            forceShowHelm ||
            (user.groups || []).filter(
                group => group === 'neptune-users' || group === 'alpha-c-users',
            ).length > 0
        );
    }, [router.query.helm, user.groups]);

    const bennuAndArenaGroupsOnly =
        user?.groups &&
        user.groups.filter(group => ['arena-staff', 'bennu-users'].includes(group)).length > 0 &&
        !user.groups.includes('force-remove-groups');

    if (router.query.helm) return null;
    return (
        <div className="flex justify-center w-full  items-center min-w-[700px]">
            {!showHelm &&
                (user?.groups.includes('bennu-users') || user?.groups.includes('arena-staff')) &&
                !user?.groups.includes('force-remove-groups') && (
                    <div className="absolute right-6 top-6">
                        <Image src="images/bennu.svg" width={220} height={40} alt="Beam Suntory" />
                    </div>
                )}
            <div className="overscroll-auto max-h-[95vh] lg:max-h-[96vh]">
                <div className="flex flex-col gap-5 p-8 mb-8 border-[transparent] z-20 glass-effect rounded-3xl">
                    <div className="flex flex-col">
                        <div className="flex flex-col gap-2">
                            <h1 className="font-light text-3xl">Welcome to the Arena</h1>
                            <span className="text-gray-400 text-sm">
                                Understand the market near real-time. Plan for the future. Trillions
                                of datapoints.
                            </span>
                        </div>
                    </div>
                    <span>Let’s get started below:</span>
                    <Container twoRows={bennuAndArenaGroupsOnly}>
                        <SubContainer
                            id="home-integrations"
                            target={false}
                            href="integrations"
                            className="hover:border-[#DF2960]"
                        >
                            <CardIconContainer>
                                <ShuffleAngular size={40} weight="light" color="#DF2960" />
                            </CardIconContainer>
                            <CardTitleContainer>Integrations</CardTitleContainer>
                            <CardDescriptionContainer>
                                Connect internal and external data sources for real-time automation.
                            </CardDescriptionContainer>
                        </SubContainer>
                        <SubContainer
                            id="home-prod-search"
                            target={false}
                            href="productSearch"
                            className="hover:border-[#C839DF]"
                        >
                            <CardIconContainer>
                                <ShoppingCart size={40} strokeWidth={1} color="#C839DF" />
                            </CardIconContainer>
                            <CardTitleContainer>Product Intelligence</CardTitleContainer>
                            <CardDescriptionContainer>
                                Monitor the factors that affect consumer demand and supply.
                            </CardDescriptionContainer>
                        </SubContainer>
                        <SubContainer
                            id="home-consumers"
                            target={false}
                            href="consumers"
                            className="hover:border-[#EBB938]"
                        >
                            <CardIconContainer>
                                <UserFocus size={40} weight="light" color="#EBB938" />
                            </CardIconContainer>
                            <CardTitleContainer>Consumer Intelligence</CardTitleContainer>
                            <CardDescriptionContainer>
                                Observe consumer behavior and demand for your brand and competitors.
                            </CardDescriptionContainer>
                        </SubContainer>
                        {bennuAndArenaGroupsOnly && (
                            <>
                                <SubContainer
                                    id="home-market-recap"
                                    target={false}
                                    href="market-recap"
                                    className="hover:border-[#00A08F]"
                                >
                                    <CardIconContainer>
                                        <Scroll size={40} weight="light" color="#00A08F" />
                                    </CardIconContainer>
                                    <CardTitleContainer>Nielsen Market Recap</CardTitleContainer>
                                    <CardDescriptionContainer>
                                        Interactive map and summary page for executive insights.
                                    </CardDescriptionContainer>
                                </SubContainer>
                                <SubContainer
                                    id="home-sales-assistant"
                                    target={false}
                                    href="sales-assistant"
                                    className="hover:border-[#F07039]"
                                >
                                    <CardIconContainer>
                                        <Handshake size={40} weight="light" color="#F07039" />
                                    </CardIconContainer>
                                    <CardTitleContainer>Sales Assistant</CardTitleContainer>
                                    <CardDescriptionContainer>
                                        Identify the top on and off premise locations to increase
                                        volume and brand value.
                                    </CardDescriptionContainer>
                                </SubContainer>
                                <SubContainer
                                    id="home-market-overview"
                                    target={false}
                                    href="market-overview"
                                    className="hover:border-[#8042E3]"
                                >
                                    <CardIconContainer>
                                        <Table size={40} weight="light" color="#8042E3" />
                                    </CardIconContainer>
                                    <CardTitleContainer>Market Overview</CardTitleContainer>
                                    <CardDescriptionContainer>
                                        View market-level insights to drive your business growth.
                                    </CardDescriptionContainer>
                                </SubContainer>
                            </>
                        )}
                        {!hideFromBennu(user?.groups) && (
                            <SubContainer
                                id="home-growth-actions"
                                target={false}
                                href="growth-actions"
                                className="hover:border-[#123FDF]"
                            >
                                <CardIconContainer>
                                    <TrendUp size={40} weight="light" color="#123FDF" />
                                </CardIconContainer>
                                <CardTitleContainer>Growth Actions</CardTitleContainer>
                                <CardDescriptionContainer>
                                    Distill insights into actions that drive sales at the store
                                    level.
                                </CardDescriptionContainer>
                            </SubContainer>
                        )}
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
